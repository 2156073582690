import {connect} from 'react-redux'
import EditEnquiry from '../components/EditEnquiry';

const mapStateToProps = (state) =>({
    role: state.profile.role
});

const mapActionCreators = {
};

export default connect (mapStateToProps,mapActionCreators)(EditEnquiry); 