import * as TripPermissions from '../modules/TripPermissions';
import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";
import context from "../../../context";

const AssignTrip = ({ role }) => {
    const { id } = useParams();
    const [trip, setTrip] = useState("");
    const [trailers, setTrailers] = useState([]);
    const [selectedTrailer, setSelectedTrailer] = useState(null);

    const fetchTrip = () => {
        axios.get(`${context.SERVER_URL}/api/trips/${id}`)
            .then((res) => {
                setTrip(res.data);
            }).catch((err) => {
                console.log(err);
            });
    };

    const fetchTrailers = () => {
        axios.get(`${context.SERVER_URL}/api/trailers`)
            .then((response) => {
                const _trailers = response.data.trailers.map((trailer) => ({
                    label: `Fleet: ${trailer.fleet?.name} | Driver: ${trailer.driver?.firstName} ${trailer.driver?.lastName} | Reg: ${trailer.registrationNumber} | Cap: L-${trailer.capacity?.leng}m, W-${trailer.capacity?.width}m, H-${trailer.capacity?.height}m`,
                    value: trailer._id,
                }));
                setTrailers(_trailers);
            }).catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchTrip();
    }, []);

    useEffect(() => {
        fetchTrailers();
    }, [trip]);

    const handleAssign = () => {
        axios.put(`${context.SERVER_URL}/api/trips/${id}/assign`, {
            data: {
                trailer: selectedTrailer?.value,
            }
        }).then((response) => {
            console.log(response);
        }).catch((error) => {
            console.log(error);
        });
    };

    return (
        <div className="flex flex-col border border-gray-300 p-6 w-full bg-white rounded-lg shadow-lg">
            <div className="flex flex-row mb-4">
                <Link
                    to="/trips"
                    className="underline text-black hover:text-gray-600 pr-2"
                >
                    Trips
                </Link>
                |
                {TripPermissions.isAuthorized("view", role) && (
                    <Link
                        to={`/trips/view/${id}`}
                        className="underline text-black hover:text-gray-600 pl-2"
                    >
                        View
                    </Link>
                )}
            </div>
            <h1 className="text-xl font-bold text-black mb-4">Assign Trip</h1>
            <div className="flex flex-wrap bg-gray-100 p-4 rounded-lg mb-6">
                <div className="flex flex-col mr-4 mb-4">
                    <label className="text-sm font-semibold text-black mb-1">Date</label>
                    <div className="rounded-lg py-2 px-3 bg-white border border-gray-300">
                        {new Date(trip.date).toLocaleDateString()}
                    </div>
                </div>
                <div className="flex flex-col">
                    <label className="text-sm font-semibold text-black mb-1">Time</label>
                    <div className="rounded-lg py-2 px-3 bg-white border border-gray-300">
                        {trip.timeslot}
                    </div>
                </div>
            </div>
            <div className="flex flex-col w-full mb-6">
                <div className="flex flex-col mb-4">
                    <label className="text-sm font-semibold text-black mb-1">Trailer</label>
                    <Select
                        className="w-full"
                        placeholder="Select Trailer"
                        options={trailers}
                        onChange={(value) => {
                            setSelectedTrailer(value);
                        }}
                        value={selectedTrailer}
                    />
                </div>
            </div>

            <div className="mb-6 w-1/3">
                <button
                    className={
                        !selectedTrailer
                            ? "w-full py-2 px-4 rounded-lg text-white font-bold bg-gray-400 cursor-not-allowed"
                            : "w-full py-2 px-4 rounded-lg text-white font-bold bg-black hover:bg-gray-800"
                    }
                    disabled={ !selectedTrailer }
                    onClick={handleAssign}
                >
                    Assign
                </button>
            </div>

            <div>
                <div className="flex flex-row w-full font-semibold text-black mb-2">
                    <div className="w-1/2 px-2">Address</div>
                    <div className="w-1/4 px-2">Point Type</div>
                    <div className="w-1/4 px-2">Capacity</div>
                </div>

                {trip?.path?.map((point, index) => (
                    <div className="flex flex-row w-full p-3 border-t border-gray-200" key={index}>
                        <div className="w-1/2 px-2">{point.address}</div>
                        <div className="w-1/4 px-2">{point.pointType}</div>
                        <div className="w-1/4 px-2">
                            {
                                point.capacity.map((pckg) => (
                                    <>
                                        <div>{`Weight: ${pckg?.weight} kg`}</div>
                                        <div>{`Length: ${pckg?.leng} m`}</div>
                                        <div>{`Width: ${pckg?.width} m`}</div>
                                        <div>{`Height: ${pckg?.height} m`}</div>
                                    </>
                                ))
                            }
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AssignTrip;
