import "react-confirm-alert/src/react-confirm-alert.css"; //

import * as BillingsPermissions from "../modules/BillingPermissions";

import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Select from "react-select";
import EditBillingEntry from "./EditBillingEntry";
const Billings = ({
  role,
  billings,
  countries,
  getBillingsAction,
  trailerCategories,
  getTrailerCategoriesAction,
  deleteBillingAction,
  updateBillingAction,
}) => {
  const [selectedCountry, setSelectedCountry] = useState({
    label: "",
    value: "",
  });

  const [billingEntries, setBillingEntries] = useState([]);

  useEffect(() => {
    getTrailerCategoriesAction();
    getBillingsAction();
  }, []);

  useEffect(() => {
    let _billings = billings.map((billing) => ({
      ...billing,
      trailerCategory: { label: billing.trailerCategory, value: billing.trailerCategory },
      trailerType: { label: billing.trailerType, value: billing.trailerType },
      country: { label: billing.country, value: billing.country },
    }));
    setBillingEntries((prevState)=>([..._billings]));

  }, [billings]);

  const onBillingChanged = (key, value, index) => {
    switch(key){
      case "capacityFrom.leng":
      case "capacityFrom.width":
      case "capacityFrom.height":
      case "capacityFrom.weight":
      case "capacityTo.leng":
      case "capacityTo.width":
      case "capacityTo.height":
      case "capacityTo.weight":
        setBillingEntries((prevState)=>{
          let _billings = [
              ...prevState
          ];
          if (value && value != ''){ 
            _billings[index][key.split('.')[0]][key.split('.')[1]] = Number(value);
          }
          else
          {
            _billings[index][key.split('.')[0]][key.split('.')[1]] = ''
          }
          return _billings;
        })
        break;
      case "trailerCategory":
      case "trailerType":
      case "country":
        setBillingEntries((prevState)=>{
          let _billings = [
            ...prevState
          ];
          if (value && value != ''){ 
            _billings[index][key] = value;
          }
          else
          {
            _billings[index][key] = ''
          }
          return _billings;
        })
        break;
      default:
        setBillingEntries((prevState)=>{
          let _billings = [
            ...prevState
          ]
          if (value && value != ''){ 
            _billings[index][key] = Number(value)
          }
          else
          {
            _billings[index][key] = ''
          }
          return _billings
        })
    }
  };

  return (
    <div className="w-full bg-white p-5 overflow-y-auto">
      <div className="flex w-full justify-between mb-2 items-center">
        <h1 className="text-primary-1 cf-headings ">Billings</h1>
        <Link to={"/billings/new"}>
          <div className="bg-primary-1 rounded-md text-white px-3 py-2">
            New Billing
          </div>
        </Link>
      </div>
      <div className="border border-gray-400 mt-1 mb-10" />
      <div className="App"></div>

      <div className="flex justify-between">
        <h1 className="pb-4 w-full text-2xl text-gray-600">
          Please select Billing Country
        </h1>
        <div className="w-full flex justify-end">
          <Select
            className="w-2/3 text-black"
            placeholder="Select Country"
            options={countries}
            onChange={(value) => setSelectedCountry(value)}
            value={selectedCountry}
          />
        </div>
      </div>

      {
        billingEntries.map((billing, index) => (
          <EditBillingEntry
            key={index}
            billing={billing}
            index={index}
            trailerCategories={trailerCategories}
            countries={countries}
            onBillingChanged={onBillingChanged}
            updateBillingAction={updateBillingAction}
            deleteBillingAction={deleteBillingAction}
          />
        ))
      }
    </div>
  );
};
export default Billings;
