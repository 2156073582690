import 'react-confirm-alert/src/react-confirm-alert.css'; //

import * as EnquiryPermissions from '../modules/EnquiryPermissions';

import React, { useEffect, useState } from "react"

import {Link} from 'react-router-dom'
import Select from 'react-select'
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert'; // Import
import context from "../../../context";
import ReactPaginate from 'react-paginate';
import { ENQUIRY_STATUSES, ENQUIRY_CATEGORIES, COUNTRY_CODES } from '../../../util/general.util';

const Enquiries = ({
    role,
    showLoaderAction,
    showErrorAction
}) => {

    const [enquiries, setEnquiries] = useState([]);

    const [filters, setFilters] = useState({
        statusFilter: { value: '', label: 'Select status' },
        searchFilter: '',
        countryFilter: { value: '', label: 'Select country code' },
        categoryFilter: { value: '', label: 'Select category' },
        pageFilter: 1,
        limitFilter: 10
    });

    const [filterOptions, _] = useState({
        statuses: ENQUIRY_STATUSES,
        categories: ENQUIRY_CATEGORIES,
        countries: COUNTRY_CODES
    });

    const [totalPages, setTotalPages] = useState(100);

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    useEffect(()=>{
        fetchEnquiries(filters);
    },[])

    const fetchEnquiries = (filters) => {
        showLoaderAction(true);
        const { searchFilter, countryFilter, categoryFilter,statusFilter, pageFilter, limitFilter } = filters;
        axios.get(`${context.SERVER_URL}/api/enquiries?searchFilter=${searchFilter}&countryCodeFilter=${countryFilter?.value}&categoryFilter=${categoryFilter?.value}&statusFilter=${statusFilter?.value}&pageFilter=${pageFilter}&limitFilter=${limitFilter}`)
        .then((response)=>{
            const { enquiries, totalPages, currentPage} = response.data;
            setEnquiries(enquiries);
            setTotalPages(totalPages);
        }).catch((error)=>{
            console.log(error);
        })
        .finally(()=>{
            showLoaderAction(false);
        })
    }

    const deleteEnquiry = (id) =>{

        const options = {
            title: 'Delete Equiry',
            message: 'Are you sure ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => {
                    axios.delete(`${context.SERVER_URL}/api/enquiries/${id}`)
                    .then((response)=>{
                        const _enquiry = enquiries.filter((enquiry)=> enquiry._id != id)
                        setEnquiries(_enquiry);
                    })
                    .catch((error)=>{
                        console.log(error);
                    })
                }
            },
            {
                label: 'No',
                onClick: () =>{}
            }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            willUnmount: () => {},
            afterClose: () => {},
            onClickOutside: () => {},
            onKeypress: () => {},
            onKeypressEscape: () => {},
            overlayClassName: "overlay-custom-class-name"
        };

        confirmAlert(options);
    }

    return(
        <div className="w-full bg-white p-5 overflow-y-auto">
            <div className="flex w-full justify-between mb-2 items-center">
                <h1 className="text-primary-1 font-bold">Enquiries</h1>
            </div>
            <div className='border border-gray-400 mt-1 mb-10'/>

            <div className="flex flex-col md:flex-row w-full gap-4" >
                <div className="grid grid-cols-5 w-full gap-4">
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">
                            Search filter
                        </label>
                        <input
                            className="w-full py-2 border px-4 rounded-lg"
                            placeholder="Search"
                            onChange={({ target: { value } }) =>
                                setFilters((prevState) => ({
                                    ...prevState,
                                    searchFilter: value,
                                }))
                            }
                            value={filters.searchFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Status filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Make"
                            options={filterOptions.statuses}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                statusFilter: value
                            }))}
                            value={filters.statusFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Category filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Model"
                            options={filterOptions.categories}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                categoryFilter: value
                            }))}
                            value={filters.categoryFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Country filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Year"
                            options={filterOptions.countries}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                countryFilter: value
                            }))}
                            value={filters.countryFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">
                            Limit filter
                        </label>
                        <input
                            className="w-fit border border-gray-200 rounded-md p-2"
                            placeholder=""
                            type = "number"
                            onChange={({target: { value }}) =>{
                                setFilters((prevState) => ({
                                ...prevState,
                                limitFilter: value,
                                }))
                            }}
                            value={filters.limitFilter}
                        />
                    </div>
                </div>
                <div className="flex flex-row gap-4 items-end">
                    <button
                        className="bg-primary-1 text-white rounded-lg px-4 py-2 h-fit"
                        onClick={()=>fetchEnquiries(filters)}
                    > Search</button>
                    <button
                        className="bg-gray-500 text-white rounded-lg px-4 py-2 h-fit"
                        onClick={()=>{
                            const resetState = {
                                statusFilter: { value: '', label: 'Select status' },
                                searchFilter: '',
                                countryFilter: { value: '', label: 'Select country code' },
                                categoryFilter: { value: '', label: 'Select category' },
                                pageFilter: 1,
                                limitFilter: 10
                            }
                            setFilters((prevState)=>({
                                ...prevState,
                                ...resetState
                            }))
                            fetchEnquiries(resetState)
                        }}
                    > Reset</button>
                </div>
            </div>

            <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                    <tr>
                        <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-primary-2 sm:pl-6"
                        >
                            Created At
                        </th>
                        <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-primary-2"
                        >
                            Status
                        </th>
                        <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-primary-2 sm:pl-6"
                        >
                            Name
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                        >
                            Surname
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                        >
                            Email
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                        >
                            Contact Number
                        </th>
                        <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-primary-2"
                        >
                            Category
                        </th>
                        <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-primary-2"
                        >
                            Message
                        </th>
                        <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-primary-2"
                        >
                            Notes
                        </th>

                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">Select</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {enquiries.map((enquiry, index) => {
                        return (
                            <tr key={enquiry._id}>
                                <td
                                    className={classNames(
                                        index === 0 ? "" : "border-t border-transparent",
                                        "relative py-4 pl-4 pr-3 text-sm sm:pl-6"
                                    )}
                                >
                                    <div className="font-medium text-primary-2">
                                        {new Date(enquiry.createdAt).toLocaleDateString()}
                                    </div>

                                </td>
                                <td
                                    className={classNames(
                                        index === 0 ? "" : "border-t border-gray-200",
                                        "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                    )}
                                >
                                    {enquiry.status}
                                </td>

                                <td
                                    className={classNames(
                                        index === 0 ? "" : "border-t border-gray-200",
                                        "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                    )}
                                >
                                    {enquiry.firstName}
                                </td>
                                <td
                                    className={classNames(
                                        index === 0 ? "" : "border-t border-gray-200",
                                        "px-3 py-3.5 text-sm text-gray-500"
                                    )}
                                >
                                    <div className="">{enquiry.lastName}</div>
                                </td>
                                <td
                                    className={classNames(
                                        index === 0 ? "" : "border-t border-gray-200",
                                        "px-3 py-3.5 text-sm text-gray-500"
                                    )}
                                >
                                    <div className="">{enquiry.email}</div>
                                </td>
                                <td
                                    className={classNames(
                                        index === 0 ? "" : "border-t border-gray-200",
                                        "px-3 py-3.5 text-sm text-gray-500"
                                    )}
                                >
                                    <div className="">{ `${enquiry.countryCode} ${enquiry.contactNumber}` }</div>
                                </td>
                                <td
                                    className={classNames(
                                        index === 0 ? "" : "border-t border-gray-200",
                                        "px-3 py-3.5 text-sm text-gray-500"
                                    )}
                                >
                                    <div className="">{ enquiry.category }</div>
                                </td>
                                <td
                                    className={classNames(
                                        index === 0 ? "" : "border-t border-gray-200",
                                        "px-3 py-3.5 text-sm text-gray-500"
                                    )}
                                >
                                    <div className="">{ enquiry.message }</div>
                                </td>
                                <td
                                    className={classNames(
                                        index === 0 ? "" : "border-t border-gray-200",
                                        "px-3 py-3.5 text-sm text-gray-500"
                                    )}
                                >
                                    <div className="">{ enquiry.notes }</div>
                                </td>
                                <td
                                    className="border-t border-transparent flex
                                        relative py-3.5 text-right text-sm font-medium"

                                >
                                    {
                                        EnquiryPermissions.isAuthorized("view", role) &&
                                        <Link to={`/enquiries/view/${enquiry._id}`}>
                                            <div className="border p-2 m-2 rounded-md">View</div>
                                        </Link>
                                    }
                                    {
                                        EnquiryPermissions.isAuthorized("edit", role) &&
                                        <Link to ={`/enquiries/edit/${enquiry._id}`}>
                                            <div  className="border border-green-500 text-primary-1 p-2 m-2 rounded-md"> Edit </div>
                                        </Link>
                                    }
                                    {
                                        EnquiryPermissions.isAuthorized("delete", role) &&
                                        <button className="border border-red-400 text-red-400 p-2 m-2 rounded-md" onClick = { ()=>deleteEnquiry(enquiry._id)}> Delete </button>
                                    }
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        <div className="flex flex-row w-full items-center justify-end mt-5">
            <ReactPaginate
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={({selected}) => {
                    let _filters = {
                    ...filters,
                    pageFilter: selected + 1
                    } 
                    (_filters);
                    setFilters(_filters);
                    fetchEnquiries(_filters);
                }}
                containerClassName={'pagination'}
                pageClassName={'page'}
                pageLinkClassName={'page-link'}
                previousClassName={'previous'}
                nextClassName={'next'}
                activeClassName={'active'}
                disabledClassName={'disabled'}      
                />
            </div>
        </div>
    )
}
export default Enquiries;