import "react-confirm-alert/src/react-confirm-alert.css"; //

import * as BookingPermissions from "../modules/BookingPermissions";

import React, { useEffect, useState } from "react";
import { TIMESLOTS, getDates } from "../../../util/date.util";

import BookingComponent from "./BookingComponent";
import Select from "react-select";
import axios from "axios";
import context from "../../../context/index";
import * as BookingNewReceiver from "../../../sockets/receivers/BookingNewReceiver"
import ReactPaginate from "react-paginate";
import '../../common/css/paginate.css'

const Bookings = ({ 
  role,
  bookings,
  getBookingsDataAction ,
  deleteBookingAction,
  currentPage,
  totalPages
}) => {
  const [filters, setFilters] = useState({
    statusFilter: null,
    searchFilter: "",
    companyFilter: null,
    dateFilter: null,
    timeslotFilter: null,
    pageFilter: 1,
    limitFilter: 10
  });

  const [filterOptions, setFilterOptions] = useState({
    statuses: [
      { value: "pending", label: "Pending" },
      { value: "scheduled", label: "Scheduled" },
      { value: "accepted", label: "Accepted" },
      { value: "onroute", label: "On Route" },
      { value: "complete", label: "Complete" },
    ],
    pathTypes: [
      { value: "direct", label: "Direct" },
      { value: "connecting", label: "Connecting" },
    ],
    companies: [],
    dates: getDates(),
    timeslots: TIMESLOTS,
  });

  const fetchCompanies = () => {
    axios
      .get(`${context.SERVER_URL}/api/companies`)
      .then((response) => {

        const companies = response.data.companies.map((company) => ({
          label: company.name,
          value: company._id,
        }));
        setFilterOptions((prevState) => ({
          ...prevState,
          companies,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const bookingNewReceiverCallback = () =>{
    getBookingsDataAction(filters)
  }

  useEffect(() => {
    fetchCompanies();
    getBookingsDataAction(filters);
  }, []);

  useEffect(()=>{
    setTimeout(()=> BookingNewReceiver.register(bookingNewReceiverCallback), 100);
    return () => BookingNewReceiver.unregister();
  }, [])

  return (
    <div className="w-full h-full p-5 overflow-auto bg-shade-1">
      <div className="flex w-full justify-between  items-center mb-2">
        <h1 className="text-primary-1 font-bold">Bookings</h1>
        {BookingPermissions.isAuthorized("new", role) && (
          <a href="/bookings/new">
            <div className="bg-primary-1 rounded-md text-white px-3 py-2">
              New Bookings
            </div>
          </a>
        )}
      </div>
      <div className="border border-gray-400 mt-1 mb-10" />
      <div className="flex flex-col w-full md:flex-row gap-4 mb-5">
        <div className="grid grid-cols-6 w-full gap-4">
          <div className="col-span-1">
            <label className="text-sm text-primary-1 font-semibold">
              Search filter
            </label>
            <input
              className="w-full py-2 px-4 rounded-lg border"
              placeholder="Search"
              onChange={({ target: { value } }) =>
                setFilters((prevState) => ({
                  ...prevState,
                  searchFilter: value,
                }))
              }
              value={filters.searchFilter}
            />
          </div>
          <div className="col-span-1">
            <label className="text-sm text-primary-1 font-semibold">
              Date filter
            </label>
            <Select
              className="w-full"
              placeholder="Select Date"
              options={filterOptions.dates}
              onChange={(value) =>
                setFilters((prevState) => ({
                  ...prevState,
                  dateFilter: value,
                }))
              }
              value={filters.dateFilter}
            />
          </div>
          <div className="col-span-1">
            <label className="text-sm text-primary-1 font-semibold">
              Timeslot filter
            </label>
            <Select
              className="w-full"
              placeholder="Select Timeslot"
              options={filterOptions.timeslots}
              onChange={(value) =>
                setFilters((prevState) => ({
                  ...prevState,
                  timeslotFilter: value,
                }))
              }
              value={filters.timeslotFilter}
            />
          </div>
          <div className="col-span-1">
            <label className="text-sm text-primary-1 font-semibold">
              Status filter
            </label>
            <Select
              className="w-full"
              placeholder="Select status"
              options={filterOptions.statuses}
              onChange={(value) =>
                setFilters((prevState) => ({
                  ...prevState,
                  statusFilter: value,
                }))
              }
              value={filters.statusFilter}
            />
          </div>
          <div className="col-span-1">
            <label className="text-sm text-primary-1 font-semibold">
              Company filter
            </label>
            <Select
              className="w-full"
              placeholder="Select Company"
              options={filterOptions.companies}
              onChange={(value) =>
                setFilters((prevState) => ({
                  ...prevState,
                  companyFilter: value,
                }))
              }
              value={filters.companyFilter}
            />
          </div>
          <div className="col-span-1">
            <label className="text-sm text-primary-1 font-semibold">
              Path type filter
            </label>
            <Select
              className="w-full"
              placeholder="Select Path Type"
              options={filterOptions.pathTypes}
              onChange={(value) =>
                setFilters((prevState) => ({
                  ...prevState,
                  pathTypeFilter: value,
                }))
              }
              value={filters.pathTypeFilter}
            />
          </div>
          <div className="col-span-1">
            <label className="text-sm text-primary-1 font-semibold">
              Limit filter
            </label>
            <input
              className="w-fit border border-gray-200 rounded-md p-2"
              placeholder=""
              type = "number"
              onChange={({target: { value }}) =>{
                setFilters((prevState) => ({
                  ...prevState,
                  limitFilter: value,
                }))
              }}
              value={filters.limitFilter}
            />
          </div>
        </div>
        <div className="flex flex-row gap-4 items-end">
          <button
            className="bg-primary-1 text-white rounded-lg px-4 py-2 h-fit"
            onClick={() => getBookingsDataAction(filters)}
          >
            {" "}
            Search
          </button>
          <button
            className="bg-gray-500 text-white rounded-lg px-4 py-2 h-fit"
            onClick={() => {
              const resetState = {
                searchFilter: "",
                dateFilter: null,
                timeslotFilter: null,
                statusFilter: null,
                companyFilter: null,
                pathTypeFilter: null,
                pageFilter: 1,
                limitFilter: 10
              };
              setFilters((prevState) => ({
                ...prevState,
                ...resetState,
              }));
              getBookingsDataAction(resetState);
            }}
          >
            {" "}
            Reset
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto ">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y  divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Booking ID
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Company
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Timeslot
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Category
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Distance
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Fare
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Path Type
                  </th>
                  <th
                    scope="col"
                    className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0"
                  >
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              {bookings?.map((booking, index) => {
                return (
                  <BookingComponent
                    index={index}
                    booking={booking}
                    deleteBookingAction = {deleteBookingAction}
                    role={role}
                  />
                );
              })}
            </table>
          </div>
        </div>
      </div>
      <div className="flex flex-row w-full items-center justify-end mt-5">
        <ReactPaginate
          previousLabel={'Previous'}
          nextLabel={'Next'}
          breakLabel={'...'}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={({selected}) => {
            let _filters = {
              ...filters,
              pageFilter: selected + 1
            } 
            getBookingsDataAction(_filters);
            setFilters(_filters);
          }}
          containerClassName={'pagination'}
          pageClassName={'page'}
          pageLinkClassName={'page-link'}
          previousClassName={'previous'}
          nextClassName={'next'}
          activeClassName={'active'}
          disabledClassName={'disabled'}      
        />
      </div>
    </div>
  );
};

export default Bookings;
