import { Link, useNavigate } from "react-router-dom"
import React,{createRef, useEffect, useState} from "react";

import AddCircleIcon from '@mui/icons-material/AddCircle';
import NewBillingEntry from './NewBillingEntry';
import { getTrailerCategoriesAction } from "../modules/billings";

const NewBilling = ({
    submitBillingsAction,
    countries,
    getCountriesAction,
    trailerCategories,
    getTrailerCategoriesAction
}) => {

    const navigate = useNavigate();
    const [billings, setBillings] = useState([]);

    const onAddBilling = () => {
        setBillings((billings)=>{
            let _billings = [...billings];
            _billings.push(
                {
                    capacityFrom:{
                        leng:'',
                        width: '',
                        height: '',
                        weight: '',
                    },
                    capacityTo:{
                        leng: '',
                        width: '',
                        height: '',
                        weight: '',
                    },
                    trailerCategory: '',
                    trailerType: '',
                    fuelPrice: '',
                    fuelConsuption: '',
                    baseFare: '',
                    serviceFee: '',
                    tripRatePerKm: '',
                    bookingRatePerKm: '',
                    profitRatePerKm: '',
                    country: ''
                }
            )
            return _billings
        })
    }

    const onBillingChanged = (key, value, index) => {
        switch(key){
            case "capacityFrom.leng":
            case "capacityFrom.width":
            case "capacityFrom.height":
            case "capacityFrom.weight":
            case "capacityTo.leng":
            case "capacityTo.width":
            case "capacityTo.height":
            case "capacityTo.weight":

                setBillings((billings)=>{
                    let _billings = [
                        ...billings
                    ];
                    if (value && value != ''){ 
                        _billings[index][key.split('.')[0]][key.split('.')[1]] = Number(value);
                    }
                    else
                    {
                        _billings[index][key.split('.')[0]][key.split('.')[1]] = ''
                    }
                    return _billings;
                })
                break;
            case "trailerCategory":
            case "trailerType":
            case "country":
                setBillings((billings)=>{
                    let _billings = [
                        ...billings
                    ];
                    if (value && value != ''){ 
                        _billings[index][key] = value;
                    }
                    else
                    {
                        _billings[index][key] = ''
                    }
                    return _billings;
                })
                break;
            default:
                setBillings((billings)=>{
                    let _billings = [
                        ...billings
                    ]
                    if (value && value != ''){ 
                        _billings[index][key] = Number(value)
                    }
                    else
                    {
                        _billings[index][key] = ''
                    }
                    return _billings
                })
        }

    }
    const onCancelBilling = (index) =>{
        setBillings((billings)=>{
            let _billings = [...billings];
            _billings.splice( index, 1)
            return _billings
        })
    }

    useEffect(()=>{
        getCountriesAction();
        getTrailerCategoriesAction();
    },[])

    return(
        <div className="w-full h-full p-5 overflow-y-auto">
            <h1 className="text-primary-1 font-bold">New Billing</h1>
            <div className='border border-gray-400 mt-1'/>
            <div className='text-gray-500 mb-10'>
                <Link 
                    to = {"/billings"}
                    className = "underline pr-2"
                >billings</Link>
            </div>
            <div className="w-full">
                <div className="flex flex-row w-full gap-5 items-center">
                    <label className="w-full text-primary-1 font-bold">Trailer Category</label>
                    <label className="w-full text-primary-1 font-bold">Trailer type</label>
                    <label className="w-full text-primary-1 font-bold">Fuel Price</label>
                    <label className="w-full text-primary-1 font-bold">Fuel Consuption</label>
                    <label className="w-full text-primary-1 font-bold">Base Fare</label>
                    <label className="w-full text-primary-1 font-bold">Service Fee</label>
                    <label className="w-full text-primary-1 font-bold">Booking Rate / km</label>
                    <label className="w-full text-primary-1 font-bold">Trip Rate / km</label>
                    <label className="w-full text-primary-1 font-bold">Capacity From</label>
                    <label className="w-full text-primary-1 font-bold">Capacity To</label>
                    <label className="text-primary-1 font-bold p-5"></label>
                </div>                
                {
                    billings.map((billing, index) => (
                        <NewBillingEntry
                            key={index}
                            index={index}
                            billing={billing}
                            onCancelBilling={onCancelBilling}
                            onBillingChanged={onBillingChanged}
                            trailerCategories={trailerCategories}
                            countries={countries}
                        />
                    ))
                }
            </div>
            <div className="flex flex-row items-center justify-between">
                <button 
                    className="py-2 px-3 bg-primary-1 text-white font-bold rounded-md mt-5" 
                    onClick={()=> submitBillingsAction({
                        billings,
                        navigate
                    })}
                >Submit</button>
                <button 
                    className="flex item-center justify-center p-1 bg-primary-1 text-white rounded-full h-fit"
                    onClick={()=>onAddBilling()}
                >
                    <AddCircleIcon fontSize="large"/>
                </button>
            </div>
                        
        </div>
    )

}

export default NewBilling;