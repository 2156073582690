import ErrorModalContainer from './routes/common/container/ErrorModalContainer'
import LoaderModalContainer from './routes/common/container/LoaderModalContainer';
import NavigationContainer from './routes/navigation/container/NavigationContainer';
import { PersistGate } from 'redux-persist/integration/react'
import {Provider} from 'react-redux'
import React from 'react';
import ReduxStorage from './store/store'
import axios from 'axios';
import { logoutAction } from './routes/login/modules/login';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

axios.interceptors.request.use(async(request)=>{

  try {
    const userToken =  ReduxStorage.store.getState()?.login.userToken;
    request.headers['Content-Type'] = 'application/json';
    request.headers['Authorization'] = `Bearer ${userToken}`;
    request.headers['ngrok-skip-browser-warning'] = 69420;
  } catch (error) {
    console.log(error);
    console.log("no token added");
  }
  return request;
},error=>{
  return Promise.reject(error);
});

axios.interceptors.response.use(async(response)=>{  
  return response;
},error=>{

  console.log(error);

  if (error?.response?.data == "Invalid Token")
  {
    try {
      ReduxStorage.store.dispatch(logoutAction())
    } catch (error) {
      console.log(error);
      console.log("Error deleting token");
    }
  }
  
  return Promise.reject(error);
});

const App = () =>{
  // localStorage.removeItem('persist:root')
  // window.location.reload();

  return (
    <div className=' h-screen bg-white'>
      <Provider store={ReduxStorage.store}> 
        <PersistGate
          loading={null}
          persistor={ReduxStorage.persistor}
        >
          <NavigationContainer/>
          <ErrorModalContainer/>
          <LoaderModalContainer/>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            // transition: Bounce
          />
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
