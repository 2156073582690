import * as EnquiryPermissions from '../modules/EnquiryPermissions'

import {Link, useParams} from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import axios from 'axios'
import context from '../../../context'

const ViewEnquiry = ({
    role
}) =>{

    const {id} = useParams();

    const [enquiry, setEnquiry] = useState({
        createdAt: '',
        firstName: '',
        lastName: '',
        email: '',
        contactNumber: '',
        countryCode: '',
        message: '',
        category: '',
        notes: "",
        status: "",
    })


    const fetchEnquiry = () => {
        axios.get(`${context.SERVER_URL}/api/enquiries/${id}`)
        .then((response)=>{

            const {
                createdAt,
                firstName,
                lastName,
                email,
                contactNumber,
                countryCode,
                message,
                category,
                status,
                notes,
            } = response.data;

            setEnquiry({
               createdAt,
                firstName,
                lastName,
                email,
                contactNumber,
                countryCode,
                message,
                category,
                status,
                notes
            })
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    useEffect(()=>{
        fetchEnquiry()
    },[])

    return(
        <div className='p-3'>
            <div className='flex flex-row'>
                <Link 
                    to = {"/enquiries"}
                    className = "underline pr-2"
                >Enquiries</Link> | 
                {
                    EnquiryPermissions.isAuthorized("edit",role) &&
                    <Link 
                        to = {`/enquiries/edit/${id}`}
                        className = "underline pl-2"
                    >edit</Link>
                }
            </div>
            <h1 className='text-primary-2 text-lg font-bold'>View Enquiry</h1>
            <div className="mt-5">
                <label className="text-primary-1 font-bold">Name</label>
                <div className="w-full p-5 rounded-lg mt-2 border">
                    {enquiry.firstName}
                </div>
            </div>
           
            <div className="mt-5">
                <label className="text-primary-1 font-bold">Surname</label>
                <div className="w-full p-5 rounded-lg mt-2 border">
                    {enquiry.lastName} 
                </div> 
            </div>

            <div className="mt-5">
                <label className="text-primary-1 font-bold">Email</label>
                <div className="w-full p-5 rounded-lg mt-2 border">
                    {enquiry.email} 
                </div> 
            </div>

            <div className="mt-5">
                <label className="text-primary-1 font-bold">Contact Number</label>
                <div className="w-full p-5 rounded-lg mt-2 border">
                    {`${enquiry.countryCode} ${enquiry.contactNumber}`} 
                </div> 
            </div>

            <div className="mt-5">
                <label className="text-primary-1 font-bold">Category</label>
                <div className="w-full p-5 rounded-lg mt-2 border">
                    {enquiry.category} 
                </div> 
            </div>

            <div className="mt-5">
                <label className="text-primary-1 font-bold">Message</label>
                <div className="w-full p-5 rounded-lg mt-2 border">
                    {enquiry.message} 
                </div> 
            </div>

            <div className="mt-5">
                <label className="text-primary-1 font-bold">Status</label>
                <div className="w-full p-5 rounded-lg mt-2 border">
                    {enquiry.status} 
                </div> 
            </div>

            <textarea
                className="w-full p-5 rounded-lg mt-5 border"
                placeholder="Notes"
                value={enquiry.notes}
            />
        </div>
    )
}

export default ViewEnquiry;