import 'react-confirm-alert/src/react-confirm-alert.css'; //

import * as ActivityPermissions from '../modules/ActivityPermissions';

import React, { useEffect, useState } from "react"

import {useNavigate} from 'react-router-dom'
import Select from 'react-select'
import { getSupportFilterDates } from '../../../util/date.util';

const Activities = ({
    role,
    activities,
    getActivitiesDataAction,
    getActivityReasonsAction,
    activityReasons,
    activityModels,
    companies,
    getCompaniesDataAction,
    fleets,
    getFleetsDataAction
}) => {

    const [dates, setDates] = useState([]);
    const [filters, setFilters] = useState({
        dateFilter: { value: '', label: 'Select Date' },
        modelFilter: { value: '', label: 'Select Model' },
        reasonFilter: { value: '', label: 'Select Reason' },
        fleetFilter: { value: '', label: 'Select Fleet' },
        companyFilter: { value: '', label: 'Select Company' },
        searchFilter: ''
    });

    const navigate = useNavigate()

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    const renderChanges = (activity) => {

        if(activity?.diff instanceof Array ){
            return (
                <div key={activity._id} className="flex flex-col">
                    <label className="font-semibold">{activity.reason}</label>
                    {/* <div className='mt-1 flex flex-row gap-2'>
                        <div className="font-semibold">{activity?.diff[0]}</div>
                        <div className="font-semibold">{activity?.diff[1]}</div>
                    </div> */}
                </div>
            );
        }

        let changes = Object.entries(activity?.diff|| {}).map(([key, value], index) => {
            return (
                <div key={index} className="flex flex-col">
                    <label className="font-semibold">{key}:</label>
                    <div className='mt-1 flex flex-row gap-2'>
                        <div className="font-semibold">{value[0]}</div>
                        <div className="font-semibold">{value[1]}</div>
                    </div>
                </div>
            );
        });

        return changes;
    }

    useEffect(()=>{
        getActivityReasonsAction();
        getFleetsDataAction();
        getCompaniesDataAction();
        let _dates = getSupportFilterDates(); 
        setDates(_dates)
        getActivitiesDataAction(filters);
    },[])


    return(
        <div className="w-full bg-white p-5 overflow-y-auto">
            <div className="flex w-full justify-between mb-2 items-center">
                <h1 className="text-primary-1 font-bold">Activities</h1>
            </div>
            <div className='border border-gray-400 mt-1 mb-10'/>

            <div className="flex flex-col md:flex-row w-full gap-4" >
                <div className="grid grid-cols-4 w-full gap-4">
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold"> Search filter </label>
                        <input
                            className="w-full py-2 px-4 rounded-lg border"
                            placeholder="Search"
                            onChange={({ target: { value } }) =>
                                setFilters((prevState) => ({
                                ...prevState,
                                searchFilter: value,
                                }))
                            }
                            value={filters.searchFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Date filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select date"
                            options={dates}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                dateFilter: value
                            }))}
                            value={filters.dateFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Model filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Model"
                            options={activityModels}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                modelFilter: value
                            }))}
                            value={filters.modelFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Reason filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Reason"
                            options={activityReasons}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                reasonFilter: value
                            }))}
                            value={filters.reasonFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Company filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Category"
                            options={companies}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                categoryFilter: value
                            }))}
                            value={filters.categoryFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Fleet filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Category"
                            options={fleets}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                categoryFilter: value
                            }))}
                            value={filters.categoryFilter}
                        />
                    </div>
                </div>
                <div className="flex flex-row gap-4 items-end">
                    <button
                        className="bg-primary-1 text-white rounded-lg px-4 py-2 h-fit"
                        onClick={()=>getActivitiesDataAction(filters)}
                    > Search</button>
                    <button
                        className="bg-gray-500 text-white rounded-lg px-4 py-2 h-fit"
                        onClick={()=>{
                            const resetState = {
                                dateFilter: { value: '', label: 'Select Date' },
                                modelFilter: { value: '', label: 'Select Status' },
                                reasonFilter: { value: '', label: 'Select Reason' },
                                fleetFilter: { value: '', label: 'Select Fleet' },
                                companyFilter: { value: '', label: 'Select Company' },
                                searchFilter: ''
                            }
                            setFilters((prevState)=>({
                                ...prevState,
                                ...resetState
                            }))
                            getActivitiesDataAction(resetState)
                        }}
                    > Reset</button>
                </div>
            </div>

            <div className="">
                <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                        <tr>
                            <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-primary-2 sm:pl-6"
                            >
                                Created At
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                            >
                                Name
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                            >
                                Reason
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                            >
                                Model
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                            >
                                Changes
                            </th>
                            <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-primary-2"
                            >
                                Company
                            </th>
                            <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-primary-2"
                            >
                                Fleet
                            </th>
                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                <span className="sr-only">Actions</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {activities.map((activity, index) => {
                            return (
                                <tr key={activity._id}>
                                    <td
                                        className={classNames(
                                            index === 0 ? "" : "border-t border-transparent",
                                            "relative py-4 pl-4 pr-3 text-sm sm:pl-6"
                                        )}
                                    >
                                        <div className="font-medium text-primary-2">
                                            {new Date(activity.createdAt).toLocaleDateString()}
                                        </div>

                                    </td>
                                    <td
                                        className={classNames(
                                            index === 0 ? "" : "border-t border-gray-200",
                                            "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                        )}
                                    >
                                        {`${activity?.user?.firstName} ${activity?.user?.lastName}`}
                                    </td>
                                    <td
                                        className={classNames(
                                            index === 0 ? "" : "border-t border-gray-200",
                                            "px-3 py-3.5 text-sm text-gray-500"
                                        )}
                                    >
                                        <div className="">{activity?.reason}</div>
                                    </td>
                                    <td
                                        className={classNames(
                                            index === 0 ? "" : "border-t border-gray-200",
                                            "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                        )}
                                    >
                                        {activity.collectionName}
                                    </td>
                                    <td
                                        className={classNames(
                                            index === 0 ? "" : "border-t border-gray-200",
                                            "px-3 py-3.5 text-sm text-gray-500"
                                        )}
                                    >
                                        { renderChanges(activity) }
                                    </td>

                                    <td
                                        className={classNames(
                                            index === 0 ? "" : "border-t border-gray-200",
                                            "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                        )}
                                    >
                                        {activity.user?.company?.name}
                                    </td>
                                    <td
                                        className={classNames(
                                            index === 0 ? "" : "border-t border-gray-200",
                                            "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                        )}
                                    >
                                        {activity.user?.fleet?.name}
                                    </td>

                                    <td className="border-t border-transparent flex relative py-3.5 text-right text-sm font-medium">
                                        {
                                            ActivityPermissions.isAuthorized("view", role) &&
                                            <button 
                                                className="border p-2 m-2 rounded-md"
                                                onClick = {()=> navigate(`/users/view/${activity.collectionId}`)} 
                                            >
                                                View
                                            </button>
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default Activities;