import update from 'react-addons-update'
import axios from "axios";
import context from "../../../context";
import actionConstants from "./actionConstants";
import { showErrorAction, showLoaderAction } from "../../common/modules/modal";

export const initialBillingsState = {
    countries: [],
    billings: [],
    billing: null,
    trailerCategories: {}
};


const {
    GET_COUNTRIES,
    GET_BILLINGS,
    GET_BILLING,
    GET_TRAILER_CATEGORIES,
} = actionConstants;


export const submitBillingsAction = ({
    billings,
    navigate
}) => {

    return (dispatch, store) => {
        dispatch(showLoaderAction(true))

        let _billings = billings.map((billing) =>({
            ...billing,
            trailerCategory: billing.trailerCategory?.value,
            trailerType: billing.trailerType?.value,
            country: billing.country?.value
        }));

        axios.post(`${context.SERVER_URL}/api/billings`,
        {
            data:
            {
                billings: _billings
            }

        }).then((response)=>{  
            console.log(response.data); 
            navigate('/billings')

        }).catch((error)=>{
            console.log(error);
            dispatch(showErrorAction({
                showError: true,
                errorMessage: `Error creating billings. - ${error.message}`
            }))
        }).finally(()=>{
            dispatch(showLoaderAction(false))
        })
    }
}

export const getCountriesAction = () => {

    return (dispatch, store) => {
        dispatch(showLoaderAction(true))
        axios.get(`${context.SERVER_URL}/api/countries`)
        .then(res=>{
            const countries = res.data.map((country)=>({ label: country, value: country }));
            dispatch({
                type: GET_COUNTRIES,
                payload:{
                    countries,
                }
            })
        })
        .catch(error=>{
            dispatch(showErrorAction({
                showError: true,
                errorMessage: `Error getting countries - ${error.message}`
            }))        
        }).finally(()=>{
            dispatch(showLoaderAction(false))
        })
    }
}

export const getTrailerCategoriesAction = () => {

    return (dispatch, store) => {
        dispatch(showLoaderAction(true))
        axios.get(`${context.SERVER_URL}/api/trailerCategories`)
        .then(res=>{
            const trailerCategories = res.data;
            dispatch({
                type: GET_TRAILER_CATEGORIES,
                payload:{
                    trailerCategories,
                }
            })
        })
        .catch(error=>{
            dispatch(showErrorAction({
                showError: true,
                errorMessage: `Error getting countries - ${error.message}`
            }))        
        }).finally(()=>{
            dispatch(showLoaderAction(false))
        })
    }
}

export const getBillingsAction = () => {

    return async (dispatch, store) => {
        try {
            const res = await axios.get(`${context.SERVER_URL}/api/billings`);
            let billings = res.data.billings;
            dispatch({
                type: GET_BILLINGS,
                payload:{
                    billings,
                }
            })
        } catch (error) {
            console.log(error);
            dispatch(showErrorAction({
                showError: true,
                errorMessage: `Error getting billings - ${error.message}`
            }))        
        }
    }
}

export const getBillingAction = (id) => {

    return (dispatch, store) => {

        axios.get(`${context.SERVER_URL}/api/billings/${id}`)
        .then(res=>{
            let billing = res.data.billing;
            dispatch({
                type: GET_BILLING,
                payload:{
                    billing,
                }
            })
        })
        .catch(error=>{
            console.log(error);
            dispatch(showErrorAction({
                showError: true,
                errorMessage: `Error getting billing - ${error.message}`
            }))        
        })
    }
}

export const deleteBillingAction = ({
    id,
}) => {
    
    return async (dispatch, store) => {
        try{
            dispatch(showLoaderAction(true))
            const response = await axios.delete(`${context.SERVER_URL}/api/billings/${id}`);
            dispatch(getBillingsAction());
        }
        catch(error){
            console.log(error);
            dispatch(showErrorAction({
                showError: true,
                errorMessage: `Error deleting billing range - ${error.message}`
            }))        
        }
        finally{
            dispatch(showLoaderAction(false))
        }
    }
}

export const updateBillingAction = ({
    id,
    billing
})=>{
    console.log("billing", billing)

    return async (dispatch, store) => {
        try{

            await dispatch(showLoaderAction(true));

            let _billing = {
                ...billing,
                trailerCategory: billing.trailerCategory?.value,
                trailerType: billing.trailerType?.value,
                country: billing.country?.value
            };

            const response = await axios.put(`${context.SERVER_URL}/api/billings/${id}`,
            {
                data:
                {
                    billing: _billing
                }

            });

            //await dispatch(getBillingsAction())
        } 
        catch(error){
            console.log(error);
            // dispatch(showErrorAction({
            //     showError: true,
            //     errorMessage: `Error updating billings range. - ${error.message}`
            // }))
        }
        finally{
            console.log("finally") 
            await dispatch(showLoaderAction(false))
        }
    }
}

function handleGetCountries (state, action){
    return update(state, {
        billings:{
            countries:{ $set: action.payload.countries },
        }
    })
}

function handleGetBillings (state, action){
    return update(state, {
        billings:{
            billings:{ $set: action.payload.billings },
        }
    })
}

function handleGetBilling (state, action){
    return update(state, {
        billings:{
            billing:{ $set: action.payload.billing },
        }
    })
}

function handleGetTrailerCategories (state, action){
    return update(state, {
        billings:{
            trailerCategories:{ $set: action.payload.trailerCategories },
        }
    })
}

export const BILLINGS_ACTION_HANDLERS = {
    GET_COUNTRIES: handleGetCountries,
    GET_BILLINGS: handleGetBillings,
    GET_BILLING: handleGetBilling,
    GET_TRAILER_CATEGORIES: handleGetTrailerCategories,
}