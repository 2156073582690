import {connect} from 'react-redux'
import Activities from '../components/Activities';
import { showLoaderAction, showErrorAction } from '../../common/modules/modal';
import { getActivitiesDataAction, getActivityReasonsAction, getCompaniesDataAction, getFleetsDataAction } from '../modules/activity';

const mapStateToProps = (state) =>({
    role:  state.profile.role,
    activities: state.activities.activities,
    activityReasons: state.activities.activityReasons,
    activityModels: state.activities.activityModels,
    companies: state.activities.companies,
    fleets: state.activities.fleets
});

const mapActionCreators = {
    showLoaderAction,
    showErrorAction,
    getActivitiesDataAction,
    getActivityReasonsAction,
    getCompaniesDataAction,
    getFleetsDataAction
};

export default connect (mapStateToProps,mapActionCreators)(Activities); 