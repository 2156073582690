import React, { useEffect, useState } from 'react';
import Select from 'react-select';

const NewBillingEntry = ({
    billing,
    index,
    onBillingChanged,
    trailerCategories,
    countries
}) => {

    const [trailerTypes, setTrailerTypes] =  useState([]);

    useEffect(()=>{
        if (billing.trailerCategory?.value){
            setTrailerTypes(trailerCategories[billing.trailerCategory.value] || [])
            onBillingChanged("trailerType", '', index)
        }
    },[billing?.trailerCategory?.value])

    return(
        <div className="flex flex-row w-full mt-5 items-center justify-between gap-5">
            <div className="w-full">
                <Select
                    className="w-full p-2 mt-2 rounded-lg"
                    options={Object.keys(trailerCategories|| {}).map((key)=>({ label: key, value: key}))}
                    value={billing.trailerCategory}
                    onChange={(value)=> onBillingChanged("trailerCategory", value, index)}
                    placeholder={"Trailer Category"}
                />
            </div>
            <div className="w-full">
                <Select
                    className="w-full p-2 mt-2 rounded-lg"
                    options={trailerTypes.map((trailerType)=>({ label: trailerType, value: trailerType}))}
                    value={billing.trailerType}
                    onChange={(value)=> onBillingChanged("trailerType", value, index)}
                    placeholder={"Trailer Type"}
                />
            </div>
            <div className="w-full">
                <input 
                    className="w-full p-2 mt-2 rounded-lg" 
                    type="number" 
                    value={billing.fuelPrice} 
                    onChange={(e)=> onBillingChanged("fuelPrice", e.target.value, index)}
                    placeholder={"Fuel Price"}
                />
            </div>
            <div className="w-full">
                <input 
                    className="w-full p-2 mt-2 rounded-lg" 
                    type="number" 
                    value={billing.fuleConsuption} 
                    onChange={(e)=> onBillingChanged("fuelConsuption", e.target.value, index)}
                    placeholder={"Fuel Consuption"}
                />
            </div>
            <div className="w-full">
                <input 
                    className="w-full p-2 mt-2 rounded-lg" 
                    type="number" 
                    value={billing.baseFare} 
                    onChange={(e)=> onBillingChanged("baseFare", e.target.value, index)}
                    placeholder={"Base Fare"}
                />
            </div>
            <div className="w-full">
                <input 
                    className="w-full p-2 mt-2 rounded-lg" 
                    type="number" 
                    value={billing.serviceFee} 
                    onChange={(e)=> onBillingChanged("serviceFee", e.target.value, index)}
                    placeholder={"Service Fee"}
                />
            </div>
            <div className="w-full">
                <input 
                    className="w-full p-2 mt-2 rounded-lg" 
                    type="number" 
                    value={billing.bookingRatePerKm} 
                    onChange={(e)=> onBillingChanged("bookingRatePerKm", e.target.value, index)}
                    placeholder={"Booking Rate"}
                />
            </div>
            <div className="w-full">
                <input 
                    className="w-full p-2 mt-2 rounded-lg" 
                    type="number" 
                    value={billing.tripRatePerKm} 
                    onChange={(e)=> onBillingChanged("tripRatePerKm", e.target.value, index)}
                    placeholder={"Trip Rate"}
                />
            </div>
            <div className="flex flex-col w-full">
                <input 
                    className="w-full p-2 mt-2 rounded-lg" 
                    type="number" 
                    value={billing.capacityFrom?.weight} 
                    onChange={(e)=> onBillingChanged("capacityFrom.weight", e.target.value, index)}
                    placeholder={"Enter Weight"}
                />
                <input 
                    className="w-full p-2 rounded-lg" 
                    type="number" 
                    value={billing.capacityFrom?.leng} 
                    onChange={(e)=> onBillingChanged("capacityFrom.leng", e.target.value, index)}
                    placeholder={"Enter length"}
                />
                <input 
                    className="w-full p-2 mt-1 rounded-lg" 
                    type="number" 
                    value={billing.capacityFrom?.width} 
                    onChange={(e)=> onBillingChanged("capacityFrom.width", e.target.value, index)}
                    placeholder={"Enter width"}
                />
                <input 
                    className="w-full p-2 mt-1 rounded-lg" 
                    type="number" 
                    value={billing.capacityFrom?.height} 
                    onChange={(e)=> onBillingChanged("capacityFrom.height", e.target.value, index)}
                    placeholder={"Enter height"}
                />
            </div>
            <div className="flex flex-col w-full">
            <div className="w-full">
                <input 
                    className="w-full p-2 mt-2 rounded-lg" 
                    type="number" 
                    value={billing.capacityTo?.weight} 
                    onChange={(e)=> onBillingChanged("capacityTo.weight", e.target.value, index)}
                    placeholder={"Enter weight"}
                />
                </div>
                <input 
                    className="w-full p-2 rounded-lg" 
                    type="number" 
                    value={billing.capacityTo?.leng} 
                    onChange={(e)=> onBillingChanged("capacityTo.leng", e.target.value, index)}
                    placeholder={"Enter length"}
                />
                <input 
                    className="w-full p-2 mt-1 rounded-lg" 
                    type="number" 
                    value={billing.capacityTo?.width} 
                    onChange={(e)=> onBillingChanged("capacityTo.width", e.target.value, index)}
                    placeholder={"Enter width"}
                />
                <input 
                    className="w-full p-2 mt-1 rounded-lg" 
                    type="number" 
                    value={billing.capacityTo?.height} 
                    onChange={(e)=> onBillingChanged("capacityTo.height", e.target.value, index)}
                    placeholder={"Enter height"}
                />
            </div>
            <Select
                className="w-full p-2 mt-2 rounded-lg"
                options={countries}
                value={billing.country}
                onChange={(value)=> onBillingChanged("country", value, index)}
                placeholder={"Country"}
            />
        </div>
    )
}

export default NewBillingEntry;