  import * as BookingPermissions from "../modules/BookingPermissions";

  import React, { useState } from "react";

  import {useNavigate } from "react-router-dom";
  import { confirmAlert } from "react-confirm-alert";
  import { COLOR_CODES } from "../../../util/general.util";
  

  const BookingComponent = ({ booking, deleteBookingAction, role, index }) => {
    const date = new Date(booking.date).toLocaleDateString();
    const navigate = useNavigate();

    const deleteBooking = (id) => {
      const options = {
        title: "Delete booking",
        message: "Are you sure ?",
        buttons: [
          {
            label: "Yes",
            onClick: () => deleteBookingAction(id),
          },
          {
            label: "No",
            onClick: () => { },
          },
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
        keyCodeForClose: [8, 32],
        willUnmount: () => { },
        afterClose: () => { },
        onClickOutside: () => { },
        onKeypress: () => { },
        onKeypressEscape: () => { },
        overlayClassName: "overlay-custom-class-name",
      };

      confirmAlert(options);
    };

    return (
      <tr className="hover:bg-gray-50 transition-colors duration-200" key={index}>
        <td className="py-4 px-6 text-sm text-gray-500">{booking._id}</td>
        <td className="py-4 px-6 text-sm font-medium text-gray-900">
          {booking.company.name}
        </td>
        <td className="py-4 px-6 text-sm text-gray-500">{date}</td>
        <td className="py-4 px-6 text-sm text-gray-500">{booking.timeslot}</td>
        <td className="py-4 px-6 text-sm text-gray-500">{booking.cargoType}</td>
        <td className="py-4 px-6 text-sm text-gray-500">{booking.distance} km</td>
        <td className="py-4 px-6 text-sm text-gray-500">${booking.fare}</td>
        <td className="py-4 px-6 text-sm">
          <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${COLOR_CODES?.booking?.status[booking.status.toLowerCase()]}`}>
            {booking.status}
          </span>
        </td>
        <td className="py-4 px-6 text-sm text-gray-500">{booking.pathType}</td>
        <td className="py-4 px-6 text-sm font-medium">
          <div className="flex justify-end space-x-2">
            {BookingPermissions.isAuthorized("view", role) && (
              <button
                onClick={ () => navigate(`/bookings/view/${booking._id}`) }
                className="text-indigo-600 hover:text-indigo-900 px-2 py-1 rounded hover:bg-indigo-100 transition-colors duration-200"
              >
                View
              </button>
            )}
            {BookingPermissions.isAuthorized("edit", role) && (
              <button
                onClick={() => navigate(`/bookings/edit/${booking._id}`)}
                className="text-blue-600 hover:text-blue-900 px-2 py-1 rounded hover:bg-blue-100 transition-colors duration-200"
              >
                Edit
              </button>
            )}
            {BookingPermissions.isAuthorized("pay", role) && (
              <button
                disabled = {booking?.payment[0] != null}  
                onClick={ () => navigate(`/payments/new/${booking._id}`)}
                className={
                  (booking?.payment[0] != null) ?
                    "text-gray-600 bg-gray-100 px-2 py-1 rounded"
                  :
                    "text-green-600 hover:text-green-900 px-2 py-1 rounded hover:bg-green-100 transition-colors duration-200"
                }
              >
                { (booking?.payment[0] == null) ? "Pay" : "Paid" }
              </button>
            )}
            {BookingPermissions.isAuthorized("delete", role) && (
              <button
                onClick={() => deleteBooking(booking._id)}
                className="text-red-600 hover:text-red-900 px-2 py-1 rounded hover:bg-red-100 transition-colors duration-200"
              >
                Delete
              </button>
            )}
          </div>
        </td>
      </tr>
    );
  };

  export default BookingComponent;
