import * as EnquiryPermissions from '../modules/EnquiryPermissions'

import {Link, useParams} from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'

import axios from 'axios'
import context from '../../../context'
import { ENQUIRY_STATUSES } from '../../../util/general.util'

const EditEnquiry = ({
    role
}) =>{

    const {id} = useParams();
    const [enquiry, setEnquiry]= useState({
        createdAt: '',
        firstName: '',
        lastName: '',
        email: '',
        contactNumber: '',
        countryCode: '',
        message: '',
        category: '',
        notes: "",
        status: { label: '', value: 'Select status'},
    });

    const fetchEnquiry = () => {
        axios.get(`${context.SERVER_URL}/api/enquiries/${id}`)
        .then((response)=>{

            const {
                createdAt,
                firstName,
                lastName,
                email,
                contactNumber,
                countryCode,
                message,
                category,
                status,
                notes,
            } = response.data;
            
            let _status = ENQUIRY_STATUSES.find((s)=> s.value === status);
            setEnquiry({
                createdAt,
                firstName,
                lastName,
                email,
                contactNumber,
                countryCode,
                message,
                category,
                status: _status,
                notes
            });
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    useEffect(()=>{
        fetchEnquiry()
    },[])

    const updateEnquiry = () =>{
        axios.put(`${context.SERVER_URL}/api/enquiries/${id}`,
        {
            data:
            {
                notes: enquiry.notes,
                status: enquiry.status?.value,
            }

        }).then((response)=>{   

            console.log(response)

        }).catch((error)=>{
            console.log(error);

        })
    }

    return(
        <div className="w-full p-3 bg-white py-8 px-16  overflow-y-auto">
            <div className='flex justify-between'>
            <h1 className="text-primary-1 font-bold">Edit Enquiry</h1>
            <Link 
                    to = {"/enquiries"}
                    className = "text-gray-400 hover:text-gray-800 pr-2"
                >Back</Link> 
            </div>
            <div className='border border-gray-400 mt-1'/>

            <div className="mt-5">
                <label className="text-primary-1 font-bold">Name</label>
                <div className="w-full p-5 rounded-lg mt-2 border">
                    {enquiry.firstName}
                </div>
            </div>
           
            <div className="mt-5">
                <label className="text-primary-1 font-bold">Surname</label>
                <div className="w-full p-5 rounded-lg mt-2 border">
                    {enquiry.lastName} 
                </div> 
            </div>

            <div className="mt-5">
                <label className="text-primary-1 font-bold">Email</label>
                <div className="w-full p-5 rounded-lg mt-2 border">
                    {enquiry.email} 
                </div> 
            </div>

            <div className="mt-5">
                <label className="text-primary-1 font-bold">Contact Number</label>
                <div className="w-full p-5 rounded-lg mt-2 border">
                    {`${enquiry.countryCode} ${enquiry.contactNumber}`} 
                </div> 
            </div>

            <div className="mt-5">
                <label className="text-primary-1 font-bold">Category</label>
                <div className="w-full p-5 rounded-lg mt-2 border">
                    {enquiry.category} 
                </div> 
            </div>

            <div className="mt-5">
                <label className="text-primary-1 font-bold">Message</label>
                <div className="w-full p-5 rounded-lg mt-2 border">
                    {enquiry.message} 
                </div> 
            </div>

            <div className="mt-5">
                <label className="text-primary-1 font-bold">Status</label>
                <Select
                    className=""
                    placeholder="Select Status"
                    options={ENQUIRY_STATUSES}
                    onChange={(value)=>setEnquiry((prevState)=>({
                        ...prevState,
                        status: value
                    }))}
                    value={enquiry.status}
                />
            </div>

            <textarea
                className="w-full p-5 rounded-lg mt-5 border"
                placeholder="Notes"
                value={enquiry.notes}
                onChange={(e)=> setEnquiry({...enquiry, notes: e.target.value})}
            />
                
            <div className=''>
                <button className="py-2 px-3 bg-primary-1 text-white font-bold rounded-md mt-5" onClick={()=> updateEnquiry()}>Update</button>
                {
                    EnquiryPermissions.isAuthorized("view",role) &&
                    <Link 
                        to = {`/enquiries/view/${id}`}
                        className = "border px-4 py-2 rounded-md ml-2 text-center hover:text-neutral-7 "
                    >Cancel</Link>
                }
            </div> 
        </div>
    )
}

export default EditEnquiry;