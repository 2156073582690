import {connect} from 'react-redux'
import Billings from '../components/Billings';
import { 
    getBillingsAction,
    deleteBillingAction,
    updateBillingAction,
    getTrailerCategoriesAction,
    getCountriesAction
} from '../modules/billings';
const mapStateToProps = (state) =>({
    role:  state.profile.role,
    billings: state.billings.billings,
    countries: state.billings.countries,
    trailerCategories: state.billings.trailerCategories
});

const mapActionCreators = {
    getBillingsAction,
    deleteBillingAction,
    updateBillingAction,
    getTrailerCategoriesAction,
    getCountriesAction
};

export default connect (mapStateToProps,mapActionCreators)(Billings); 