import React, { useState, useEffect } from 'react'
import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import axios from 'axios';
import CancelIcon from '@mui/icons-material/Cancel';
import PersonIcon from '@mui/icons-material/Person';
import Select from 'react-select';
import { TIMESLOTS, getDates } from '../../../../util/date.util';
import context from '../../../../context';

const GroupingDialogBox = ({
    groupingState,
    onGroupBookings,
    onOrderSelectedBookingsPath,
    onAddBookingsToGroup,
    onRemoveBookingFromGroup,
    onReorderGroupBookings,
    openInGoogleMaps
}) =>{

    const [showReorderError, setShowReorderError] = useState(false);
    const [selectedTimeslot, setSelectedTimeslot] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTrailerCategory, setSelectedTrailerCategory] = useState('');
    const [selectedTrailerType, setSelectedTrailerType] = useState('');
    const [trailerCategories, setTrailerCategories] =  useState([]);
    const [trailerTypes, setTrailerTypes] = useState([]);

    const fetchTrailerCategories = () => {
        axios.get(`${context.SERVER_URL}/api/trailerCategories`)
        .then((response)=>{
            let categories = response.data;
            setTrailerCategories(categories);
        }).catch((error)=>{
            console.log(error);
        })
    }

    const getCapacityAttributes = (transitCapacity) =>{
        return transitCapacity.reduce((acc, product)=>{
            return {
                quantity: acc.quantity + product?.quantity || 0,
                weight: acc.weight + product?.weight || 0,
                volume: acc.volume + product?.volume || 0
            }
        }, {
            quantity: 0,
            weight: 0,
            volume: 0
        })
    }

    const SortableBooking = SortableElement(({value}) =>{
        const {booking, type, label, transitCapacity, trip} = value;
        const date = new Date(booking.date).toLocaleDateString();
        const capacityAttributes= getCapacityAttributes(transitCapacity);   

        return(
            <tr className={
                (type == "location") ?
                    "border-t-2 border-gray-300 text-sm text-primary-1 bg-green-200"
                :
                    "border-t-2 border-gray-300 text-sm text-sm text-red-500 bg-red-200"
            }
            >
                <td className="py-1 px-2 text-xs">
                    <div className="bg-white font-bold p-1 rounded-lg">{label}</div>
                </td>
                <td className="py-1 px-2 text-xs">{date}</td>
                <td className="py-1 px-2 text-xs">{booking.timeslot}</td>
                <td className="py-1 px-2 text-xs">
                    <div className="flex flex-col p-2">
                        <label className="mr-2">Capacity:</label>
                        <div className="">{`weight: ${capacityAttributes?.weight}`}</div>
                        <div className="">{`volume: ${capacityAttributes?.volume}`}</div>
                        <div className="">{`Quantity: ${capacityAttributes?.quantity}`}</div>
                    </div>
                </td>
                {
                    (trip) &&
                    <td className="py-1 px-2 text-xs">
                        <button
                            onClick={()=>onRemoveBookingFromGroup(booking)}
                        >
                            <CancelIcon
                                color='#FFFFFF'
                            />
                        </button>
                    </td>
                }
            </tr>
        )
    });
    
    const SortableBookingsList = SortableContainer(({items}) => {
        return (
          <tbody>
            {
                items.map((point, index) => {
    
                    return(
                        <SortableBooking key={`path-${point.booking._id}-${point.type}-${point.order}`} index={index} value={point} />
                    )
                })
            }
          </tbody>
        );
    });
    

    const onSortEnd = ({oldIndex, newIndex}) =>{

        // validate sort here

        const { booking, type, order } = groupingState.selectedBookingsPath[oldIndex];
        let alternatePointType = (type == "location") ? "destination" : "location";
        let incorrectLeadingPoints = [];

        //point can moved relative to other location points but cannot be after detinations depending on order
        incorrectLeadingPoints = groupingState.selectedBookingsPath.filter((point, index)=>{

            if( booking._id == point.booking._id && point.type == alternatePointType)
            {
                if ((type == "location" && index <= newIndex && point.order > order) || (type == "destination" && index >= newIndex && point.order < order))
                {
                    return true

                }
            }
            return false
        });

        if ( incorrectLeadingPoints.length != 0)
        {
            setShowReorderError(true);
            setTimeout(()=>{
                setShowReorderError(false);
            },3000)
            return
        }

        onOrderSelectedBookingsPath({oldIndex, newIndex});
    }

    const getActionButton  = () =>{
        const { selectedPath, selectedBookings, selectedTrips, trips } = groupingState;
        
        if (selectedTrips.length != 0 && selectedBookings.length != 0)
        {
            return(
                <button
                    className='text-sm text-white bg-gray-500 py-2 px-3 w-full mt-2 rounded-lg'
                    onClick={()=> onAddBookingsToGroup()}
                >Add to Group</button>
            )
        }
        else if (selectedTrips.length != 0)
        {
            // // check if trip path is the same as the selected bookings path
            // const trip = trips.filter((trip)=> trip._id == selectedTripBookings[0].trip)[0]
            // //find the trip                        
            // const hasBeenReordered = trip.path.some((point, index)=> {

            //     if (point._id != selectedBookingsPath[index]._id)
            //     {
            //         return true;
            //     }
            //     return false
            // })

            // if(hasBeenReordered)
            // {
            //     return(
            //         <button
            //             className='text-sm text-white bg-gray-500 py-2 px-3 w-full mt-2 rounded-lg'
            //             onClick={()=> onReorderGroupBookings()}
            //         >Re-Order Trip</button>
            //     )
            // }
            // else{
            //     return(<></>)
            // }

           
        }
        else
        {
            return(
                <button
                    className='text-sm text-white bg-gray-500 py-2 px-3 w-full mt-2 rounded-lg'
                    onClick={()=> onGroupBookings({
                        selectedDate: selectedDate?.value,
                        selectedTimeslot: selectedTimeslot?.value,
                        selectedTrailerCategory: selectedTrailerCategory?.value,
                        selectedTrailerType: selectedTrailerType?.value
                    })}
                >Group</button>
            )
        }
        
    }

    useEffect(()=>{
        fetchTrailerCategories();
    },[]);

    useEffect(()=>{
        if(selectedTrailerCategory != '')
        {
            setTrailerTypes(trailerCategories[selectedTrailerCategory?.value]);
            setSelectedTrailerType('');
        }
    }, [selectedTrailerCategory?.value]);

    return(
        <div className='absolute right-3 top-16 bg-white rounded-lg p-5 items-center justify-center text-sm w-90'>
            <div className='flex flex-row justify-between items-center border-b-2 border-gray-500 p-1'>
                <h1 className='text-gray-500 font-bold text-xs'>Selected Bookings</h1>
                <button
                    className='py-1 px-2 bg-gray-500 text-sm text-white rounded-lg'
                    onClick={()=>openInGoogleMaps(groupingState.selectedBookingsPath)}
                > View In Google Maps</button>

            </div>
            <div className='flex flex-col mt-2 w-full gap-2'>
                <Select
                    className="w-full"
                    placeholder="Select Date"
                    options={getDates()}
                    onChange={(value)=>setSelectedDate(value)}
                    value={selectedDate}
                />
                <Select
                    className="w-full"
                    options={TIMESLOTS}
                    value={selectedTimeslot}
                    onChange={(value)=> setSelectedTimeslot(value)}
                    placeholder='Select Timeslot'
                />
                <div className="w-full">
                    <Select
                        className="w-full mt-2"
                        placeholder="Select trailer category"
                        options={Object.keys(trailerCategories).map((key)=>({ label: key, value: key })) }
                        onChange={(value)=>setSelectedTrailerCategory(value)}
                        value={selectedTrailerCategory}
                    />
                </div>

                {
                    (selectedTrailerCategory?.value != '') &&
                    <div className="w-full">
                        <Select
                            className="w-full mt-2"
                            placeholder="Select cargo type"
                            options={trailerTypes.map((type)=>({ label: type, value: type }))}
                            onChange={(value)=>setSelectedTrailerType(value)}
                            value={selectedTrailerType}
                        />
                    </div>
                }
            </div>
            <table className='w-full mt-2'>
                <thead className='text-gray-500 font-bold text-left text-xs'>
                    <tr>
                        <th></th>
                        <th className="py-1 px-2">Date</th>
                        <th className="py-1 px-2">Time</th>
                        <th className="py-1 px-2">Weight</th>
                        <th className="py-1 px-2">Remove</th>

                    </tr>
                </thead>
                <SortableBookingsList 
                    //distance={1}
                    pressDelay = {56}
                    items={groupingState.selectedPath} 
                    onSortEnd={onSortEnd} 
                />
            </table>
            
            {
                getActionButton()
            }
            {
                showReorderError ?
                    <div className='p-5 text-sm text-yellow-500 items-center justify-center w-full bg-yellow-200 mt-2 rounded-lg'>
                        <p>Dropoff point for booking cannot be placed before the pickup location.</p>
                    </div>
                :
                    <div className='text-sm text-gray-500 items-center justify-center w-full mt-2'>
                        Drag and drop to re-order way points.
                    </div>
            }
        </div>
    )
}

export default GroupingDialogBox;