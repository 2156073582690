import {connect} from 'react-redux'
import NewBilling from '../components/NewBilling';
import { showErrorAction } from '../../common/modules/modal';
import { 
    submitBillingsAction,
    getCountriesAction,
    getTrailerCategoriesAction 
} from '../modules/billings';

const mapStateToProps = (state) =>({
    role: state.profile.role,
    countries: state.billings.countries,
    trailerCategories: state.billings.trailerCategories
});

const mapActionCreators = {
    showErrorAction,
    submitBillingsAction,
    getCountriesAction,
    getTrailerCategoriesAction
};

export default connect (mapStateToProps,mapActionCreators)(NewBilling); 