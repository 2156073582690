import update from 'react-addons-update'
import actionConstants from './actionConstants'
import axios from 'axios';
import context from '../../../context/index';
import { showLoaderAction } from '../../common/modules/modal';
export const initialActivityState = {
    activities: [],
    activityReasons: [],
    activityModels: [],
    companies: [],
    fleets: [],
};

const {
    GET_ACTIVITIES_DATA,
    GET_ACTIVITY_REASONS,
    GET_ACTIVITY_COMPANIES_DATA,
    GET_ACTIVITY_FLEETS_DATA
} = actionConstants;

export const getActivitiesDataAction = (filters) => {
    return (dispatch, store) =>{
        dispatch(showLoaderAction(true));
        const {
            searchFilter,
            dateFilter,
            modelFilter,
            reasonFilter,
            fleetFilter,
            companyFilter,
        } = filters;

        let _date = "";
        if (dateFilter?.value) {
            const date = new Date(dateFilter.value);
            _date = JSON.stringify({
                month: date.getMonth(),
                date: date.getDate(),
                year: date.getFullYear(),
            });
        }

        const params = new URLSearchParams({
            searchFilter: searchFilter || "",
            dateFilter: _date,
            modelFilter: modelFilter?.value || "",
            reasonFilter: reasonFilter?.value || "",
            fleetFilter: fleetFilter?.value || "",
            companyFilter: companyFilter?.value || ""
        });

        axios.get(`${context.SERVER_URL}/api/activities?${params.toString()}`)
        .then((res) => {
            const activities  = res.data.activities;
            dispatch({
                type: GET_ACTIVITIES_DATA,
                payload:{
                    activities
                }
            })      
        })
        .catch((err) => {
            console.error("Error fetching activities:", err);
        })
        .finally(()=>{
            dispatch(showLoaderAction(false));
        })
    }
};

export const getActivityReasonsAction = () => {
    return (dispatch, store) =>{
        dispatch(showLoaderAction(true));
        axios.get(`${context.SERVER_URL}/api/activityReasons`)
        .then((res) => {
            const activityReasons = res.data.activityReasons;

            let _activityModels = activityReasons.map((reason) => {
                let _model = reason.split(".")[0];
                return {
                    label: `${_model.charAt(0).toUpperCase() + _model.slice(1)}Model`,
                    value: `${_model.charAt(0).toUpperCase() + _model.slice(1)}Model`,
                }
            }); 

            let _activityReasons = activityReasons.map((reason) => ({
                label: reason,
                value: reason,
            }));

            dispatch({
                type: GET_ACTIVITY_REASONS,
                payload:{
                    activityReasons: _activityReasons,
                    activityModels:  Array.from(new Map(_activityModels.map(item => [item.value, item])).values())
                }
            })      
        })
        .catch((err) => {
            console.error("Error fetching activity reasons:", err);
        })
        .finally(()=>{
            dispatch(showLoaderAction(false));
        })
    }
};

export const getCompaniesDataAction = () => {
    return (dispatch, store) =>{
        dispatch(showLoaderAction(true));

        axios.get(`${context.SERVER_URL}/api/companies`)
        .then((res) => {
            const {companies}  = res.data;

            const _companies = companies.map((company) => ({
                label: company.name,
                value: company._id,
            }))

            dispatch({
                type: GET_ACTIVITY_COMPANIES_DATA,
                payload:{
                    companies: _companies,
                }
            })      
        })
        .catch((err) => {
            console.error("Error fetching companies:", err);
        })
        .finally(()=>{
            dispatch(showLoaderAction(false));
        })
    }
};

export const getFleetsDataAction = () => {
    return (dispatch, store) =>{
        dispatch(showLoaderAction(true));

        axios.get(`${context.SERVER_URL}/api/fleets`)
        .then((res) => {
            const {fleets}  = res.data;

            const _fleets = fleets.map((fleet) => ({
                label: fleet.name,
                value: fleet._id,
            }))

            dispatch({
                type: GET_ACTIVITY_FLEETS_DATA,
                payload:{
                    fleets: _fleets,
                }
            })      
        })
        .catch((err) => {
            console.error("Error fetching fleets:", err);
        })
        .finally(()=>{
            dispatch(showLoaderAction(false));
        })
    }
};



function handleGetActivitiesData (state, action){
    return update(state, {
        activities:{
            activities:{ $set: action.payload.activities },
        }
    })
}

function handleGetActivityReasons (state, action){
    return update(state, {
        activities:{
            activityReasons:{ $set: action.payload.activityReasons },
            activityModels:{ $set: action.payload.activityModels },
        }
    })
}

function handleGetCompaniesData (state, action){
    return update(state, {
        activities:{
            companies:{ $set: action.payload.companies },
        }
    })
}

function handleGetFleetsData (state, action){
    return update(state, {
        activities:{
            fleets:{ $set: action.payload.fleets },
        }
    })
}

export const ACTIVITIES_ACTION_HANDLERS = {
    GET_ACTIVITIES_DATA: handleGetActivitiesData,
    GET_ACTIVITY_REASONS: handleGetActivityReasons,
    GET_ACTIVITY_COMPANIES_DATA: handleGetCompaniesData,
    GET_ACTIVITY_FLEETS_DATA: handleGetFleetsData
}